import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isBetaListVisible, setIsBetaListVisible] = useState(false);
  const betaListRef = useRef(null); // Ref para o container do menu

  // Alterna a visibilidade da lista
  const toggleBetaList = (event) => {
    event.stopPropagation(); // Impede que o evento propague para o listener global
    setIsBetaListVisible((prev) => !prev); // Alterna o estado
  };

  // Fecha o menu ao clicar fora
  const handleClickOutside = (event) => {
    if (betaListRef.current && !betaListRef.current.contains(event.target)) {
      setIsBetaListVisible(false); // Fecha o menu
    }
  };

  // Fecha o menu ao clicar em um link do menu
  const handleLinkClick = () => {
    setIsBetaListVisible(false); // Fecha o menu
  };

  // Adiciona e remove o listener global
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <div className="header-left">
        <button
          className={`beta-span ${isBetaListVisible ? "open" : ""}`}
          onClick={toggleBetaList}
        >
          IDX DO FUTURO
        </button>
        <button
          className={`beta-toggle ${isBetaListVisible ? "open" : ""}`}
          onClick={toggleBetaList}
        ></button>

        {isBetaListVisible && (
          <div className="beta-list" ref={betaListRef}>
            <ul>
              <li>
                <Link to="/idcast" onClick={handleLinkClick}>
                  IDCAST
                </Link>
              </li>
              <li>
                <Link to="/idgroup" onClick={handleLinkClick}>
                  IDGROUP
                </Link>
              </li>
              <li>
                <Link to="/idgame" onClick={handleLinkClick}>
                  GAMID
                </Link>
              </li>
              <li>
                <Link to="/idacademy" onClick={handleLinkClick}>
                  IDACADEMY
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>

      <div className="header-center">
        <Link to="/" className="home-button">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s"
            alt="Home"
          />
        </Link>
      </div>

      <div className="header-right">
        <a
          href="https://sejaparceiro.idbrasildigital.com.br/"
          className="seja-parceiro"
          target="_blank"
          rel="noopener noreferrer"
        >
          SEJA PARCEIRO
        </a>
      </div>
    </header>
  );
};

export default Header;