import React from 'react';

// Componente MainTitle, exibe o título principal na página inicial
const MainTitle = () => (
  <>
    <h2 className="sub-title">
      SEJA UM PARCEIRO IDX <strong>E GANHE SEM OPERACIONAL</strong>
    </h2>
    <h1 className="main-title"><strong>Nossos Serviços</strong></h1>
  </>
);

export default MainTitle;