import React, { useState } from "react";

// Componente Planner, exibe as opções de conteúdo baseado na opção selecionada
const Planner = ({ selectedOption }) => {
  // Estado para gerenciar a exibição da subview (detalhes dos itens)
  const [subView, setSubView] = useState(null);

  // Função que lida com o clique nos botões para exibir a subview do item
  const handleButtonClick = (button) => {
    setSubView(button); // Exibe os detalhes do item clicado
  };

  // Função que lida com o clique no botão de "voltar", retornando à lista de opções
  const handleBackClick = () => {
    setSubView(null); // Reseta o estado da subview para null, voltando à lista de opções
  };

  // Dados das opções que são exibidas dependendo da categoria selecionada
  const buttonData = {
    "Sistema": [
      { id: "Website",
        buttonTitle: "Website",
        contentTitle: "Website",
        content: "Desenvolvemos websites modernos,  responsivos e otimizados para SEO,  que representam a identidade da sua marca e  geram resultados.", 
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Landing Pages",
        buttonTitle: "Landing Pages", 
        contentTitle: "Landing Pages", 
        content: "Criamos landing pages personalizadas e focadas em conversão,  que capturam leads e  impulsionam suas vendas.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Ecommerce",
        buttonTitle: "Ecommerce", 
        contentTitle: "Ecommerce", 
        content: "Desenvolvemos lojas virtuais completas e seguras,  com design intuitivo e  funcionalidades avançadas,  para que você venda online com sucesso.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "CRM", 
        buttonTitle: "CRM",
        contentTitle: "CRM", 
        content: "Implementamos sistemas de CRM que organizam seus contatos,  facilitam a gestão de clientes e  otimizam o relacionamento com seu público.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Sistema de NF-e",
        buttonTitle: "Sistema de NF-e", 
        contentTitle: "Sistema de NF-e", 
        content: "Oferecemos sistemas de emissão de notas fiscais eletrônicas eficientes e seguros,  que simplificam sua gestão fiscal e  garantem o cumprimento da legislação.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "ERP", 
        buttonTitle: "ERP",
        contentTitle: "ERP", 
        content: "Implementamos sistemas ERP que integram todos os processos da sua empresa,  do financeiro ao estoque,  das vendas ao RH,  aumentando a eficiência e  otimizando a gestão.",       
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "PontoMais",
        buttonTitle: "PontoMais", 
        contentTitle: "PontoMais", 
        content: "Oferecemos sistemas de controle de ponto eletrônico que facilitam a gestão da jornada de trabalho dos seus colaboradores,  garantindo o cumprimento da legislação e  aumentando a produtividade.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Inteligência Artificial",
        buttonTitle: "Inteligência Artificial", 
        contentTitle: "Inteligência <br>Artificial", 
        content: "Desenvolvemos soluções personalizadas com inteligência artificial,  que automatizam tarefas,  analisam dados e  tomam decisões estratégicas,  impulsionando seus resultados.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },
    ],
    "Marketing": [
      { id: "Identidade Visual",
        buttonTitle: "Identidade Visual", 
        contentTitle: "Identidade Visual", 
        content: "Construímos marcas memoráveis, desde a concepção da logo até a definição da paleta de cores e tipografia.  Sua identidade visual será única e impactante, transmitindo a essência da sua empresa.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Gestão de Redes Sociais",
        buttonTitle: "Gestão de Redes Socias", 
        contentTitle: "Gestão de Redes <br>Socias", 
        content: "Do planejamento à execução,  cuidamos das suas redes sociais com maestria.  Criamos conteúdo relevante,  interagimos com seu público e  impulsionamos seus resultados.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Tráfego Pago",
        buttonTitle: "Tráfego Pago", 
        contentTitle: "Tráfego Pago", 
        content: "Atraímos clientes qualificados com campanhas de anúncios personalizadas no Google, Facebook, Instagram e outras plataformas.  Investimos seu dinheiro com inteligência e geramos resultados mensuráveis.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Design", 
        buttonTitle: "Design",
        contentTitle: "Design", 
        content: "Criamos peças gráficas que comunicam e inspiram,  desde posts para redes sociais até materiais impressos como flyers, banners e catálogos.  Seu material de divulgação será profissional e memorável.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Consultoria",
        buttonTitle: "Consultoria", 
        contentTitle: "Consultoria", 
        content: "Oferecemos consultoria estratégica em marketing digital,  analisando suas necessidades,  definindo metas e  traçando planos de ação eficazes.  Guiamos você no caminho do sucesso digital.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Profissionalização",
        buttonTitle: "Profissionalização", 
        contentTitle: "Profissionalização", 
        content: "Elevamos o nível do seu perfil profissional online,  criando conteúdo de alta qualidade,  otimizando sua presença nas redes sociais e  construindo uma imagem impecável.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Audiovisual",
        buttonTitle: "Audiovisual", 
        contentTitle: "Audiovisual", 
        content: "Damos vida às suas ideias com vídeos criativos e profissionais,  desde animações e vídeos institucionais até  conteúdo para redes sociais.  Seu público vai se encantar com a qualidade e o impacto dos seus vídeos.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Experiência 360 Graus",
        buttonTitle: "Experiência 360 Graus", 
        contentTitle: "Experiência 360<br>Graus", 
        content: "Oferecemos soluções completas em marketing digital,  desde a criação de websites até a gestão de redes sociais e campanhas de tráfego pago.  Construímos uma presença digital impecável para sua marca.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Google Meu Negócio",
        buttonTitle: "Google Meu Negócio", 
        contentTitle: "Google Meu<br>Negócio", 
        content: "Posicionamos seu negócio no topo das buscas locais,  otimizando seu perfil no Google Meu Negócio para que você seja encontrado por clientes em sua região.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "SEO", 
        buttonTitle: "SEO",
        contentTitle: "SEO", 
        content: "Aumentamos a visibilidade do seu site nos mecanismos de busca,  aplicando técnicas de SEO para que você conquiste as primeiras posições no Google e atraia mais visitantes.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Funil de Vendas",
        buttonTitle: "Funil de Vendas", 
        contentTitle: "Funil de Vendas", 
        content: "Criamos funis de vendas eficazes que guiam seus clientes pela jornada de compra,  desde a atração até a conversão.  Aumentamos suas vendas e impulsionamos seus resultados.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },
    ],
    "Certificado Digital": [
      { id: "Botão1", 
        buttonTitle: "Botão1",
        contentTitle: "Botão1", 
        content: "",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Botão2", 
        buttonTitle: "Botão2",
        contentTitle: "Botão2",
        content: "",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Botão3", 
        buttonTitle: "Botão3",
        contentTitle: "Botão3", 
        content: "",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Botão4", 
        buttonTitle: "Botão4",
        contentTitle: "Botão4", 
        content: "",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Botão5", 
        buttonTitle: "Botão5",
        contentTitle: "Botão5", 
        content: "",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Botão6", 
        buttonTitle: "Botão6",
        contentTitle: "Botão6", 
        content: "",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },
    ],
  };

  // Função para renderizar os botões dependendo da opção selecionada
  const renderButtons = () => {
    const buttons = buttonData[selectedOption];
    return buttons.map((button) => (
      <button
        key={button.id}
        className={`planner-button planner-button-${button.id.replace(/\s+/g, '-')} show-img`} 
        onClick={() => handleButtonClick(button)}
      >
        <p className="button-title">{button.buttonTitle}</p>
      </button>
    ));
  };

  const renderContent = () => {
    if (!subView) {
      return (
        <>
          <h2>Você escolheu: {selectedOption}</h2>
          <div className="button-grid">{renderButtons()}</div>
        </>
      );
    } else {
      return (
        <>
          <button className="back-button" onClick={handleBackClick}>
            Voltar
          </button>
          <div className="content-container">
            {/* Use dangerouslySetInnerHTML para o contentTitle */}
            <h3 className="content-title" dangerouslySetInnerHTML={{ __html: subView.contentTitle }}></h3>
            <div className="content" dangerouslySetInnerHTML={{ __html: subView.content }}></div>
            <img src={subView.img} alt={subView.contentTitle} className="subview-img" />
            {subView.video && (
              <video controls className="subview-video">
                <source src={subView.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </>
      );
    }
  };  

  return selectedOption ? (
    <section className="planner">
      {renderContent()}
    </section>
  ) : null;   
};

export default Planner;