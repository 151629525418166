import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// Importando componentes utilizados na aplicação
import Header from "./components/Header";
import MainTitle from "./components/MainTitle";
import Toolbar from "./components/Toolbar";
import "./App.css";  

// Páginas da aplicação
import IdAcademy from "./pages/IdAcademy";
import IdCast from "./pages/IdCast";
import IdGroup from "./pages/IdGroup";
import IdGame from "./pages/IdGame";
import Planner from "./components/Planner";

// Layout da aplicação
import Layout from "./components/Layout";

// Função principal do App
function App() {
  // Definindo o estado do tema (escuro ou claro) com base na configuração salva no localStorage
  const [darkMode, setDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme === "dark" : true;  // Define o tema como escuro por padrão, caso não exista preferência salva
  });

  // Hook do React Router para obter a localização atual da rota
  const location = useLocation();

  // Função que alterna entre os temas claro e escuro e salva essa preferência no localStorage
  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;  // Alterna entre os estados do tema
    setDarkMode(newDarkMode);  // Atualiza o estado do tema
    localStorage.setItem("theme", newDarkMode ? "dark" : "light");  // Salva a preferência no localStorage
  };

  // Definindo o estado para armazenar a opção selecionada no Planner
  const [selectedOption, setSelectedOption] = useState(null);

  // Lista de rotas onde a barra de ferramentas (Toolbar) será exibida
  const showToolbarRoutes = ["/", "/planner"];

  // Função que retorna o título da página com base na rota atual
  const renderPageTitle = () => {
    switch (location.pathname) {
      case "/idcast":
        return "Bem-vindo ao IDCAST";  // Título para a página IDCAST
      case "/idgroup":
        return "Bem-vindo ao IDGROUP";  // Título para a página IDGROUP
      case "/idgame":
        return "Bem-vindo ao GAMID";  // Título para a página IDGAME
      case "/idacademy":
        return "Bem-vindo ao IDACADEMY";  // Título para a página IDACADEMY
      default:
        return null;  // Caso não tenha uma rota definida, retorna null
    }
  };

  return (
    <div className={`app ${darkMode ? "dark-mode" : "light-mode"}`}>  {/* Aplica a classe do tema (escuro ou claro) à div principal */}
      {/* Componente Header, passando as funções de alternância de tema */}
      <Header toggleDarkMode={toggleDarkMode} darkMode={darkMode} />

      {/* Exibe o título principal apenas na página inicial */}
      {location.pathname === "/" && <MainTitle />}

      {/* Exibe a barra de ferramentas apenas nas rotas configuradas */}
      {showToolbarRoutes.includes(location.pathname) && (
        <Toolbar onButtonClick={setSelectedOption} />
      )}

      {/* Exibe o título da página, exceto na página inicial */}
      <div className="main-content">
        {location.pathname !== "/" && (
          <h1 className="page-title">{renderPageTitle()}</h1>
        )}

        {/* Definindo as rotas da aplicação */}
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Planner selectedOption={selectedOption} />} />
          </Route>
          <Route path="/idcast" element={<IdCast />} />
          <Route path="/idgroup" element={<IdGroup />} />
          <Route path="/idgame" element={<IdGame />} />
          <Route path="/idacademy" element={<IdAcademy />} />
        </Routes>
      </div>

      {/* Rodapé da página */}
      <footer className="footer">© 2024 IDX Company. Todos os direitos reservados.</footer>

    </div>
  );
}

export default App;