import React, { useEffect, useRef, useState, useMemo } from "react";

// Componente Toolbar exibe os botões de navegação e gerencia a movimentação do indicador.
const Toolbar = ({ onButtonClick }) => {
  // Estado para gerenciar a aba ativa (inicialmente "Sistema")
  const [active, setActive] = useState("Sistema");
  
  // Referência para o indicador (a linha que se move abaixo dos botões)
  const indicatorRef = useRef(null);
  
  // Referência para os botões da barra de navegação
  const buttonsRef = useRef([]);

  // Lista das abas (Sistema, Marketing, Certificado Digital)
  const tabs = useMemo(() => ["Marketing", "Sistema", "Certificado Digital"], []);

  // useEffect é acionado toda vez que a aba ativa ou a lista de abas muda
  useEffect(() => {
    // Obtém o botão ativo da lista de botões, com base na aba ativa
    const activeButton = buttonsRef.current[tabs.indexOf(active)];
    const indicator = indicatorRef.current;

    // Se o botão ativo e o indicador existirem, calcula a posição e o tamanho do indicador
    if (activeButton && indicator) {
      const buttonWidth = activeButton.offsetWidth;  // Largura do botão ativo
      const buttonLeft = activeButton.offsetLeft;    // Posição do botão ativo em relação à esquerda

      // Ajusta a largura e a posição do indicador
      indicator.style.width = `${buttonWidth}px`;
      indicator.style.transform = `translateX(${buttonLeft}px)`;  // Move o indicador para a posição do botão ativo
    }
  }, [active, tabs]); // A dependência do efeito inclui "active" e "tabs", ou seja, ele será executado sempre que a aba ativa ou a lista de abas mudar

  // Função chamada ao clicar em uma aba
  const handleClick = (option) => {
    setActive(option);  // Atualiza o estado da aba ativa
    if (onButtonClick) onButtonClick(option);  // Chama a função de callback passada via props, se existir
  };

  return (
    <nav className="toolbar">
      <div className="toolbar-left">
        <button 
          ref={(el) => (buttonsRef.current[0] = el)}
          className={`toolbar-button ${active === "Marketing" ? "active" : ""}`}
          onClick={() => handleClick("Marketing")}
        >
          Marketing
        </button>
        <span className="separator">|</span>
      </div>
      <div className="toolbar-center">
        <button 
          ref={(el) => (buttonsRef.current[1] = el)}
          className={`toolbar-button ${active === "Sistema" ? "active" : ""}`}
          onClick={() => handleClick("Sistema")}
        >
          Sistema
        </button>
      </div>
      <div className="toolbar-right">
        <span className="separator">|</span>
        <button 
          ref={(el) => (buttonsRef.current[2] = el)}
          className={`toolbar-button ${active === "Certificado Digital" ? "active" : ""}`}
          onClick={() => handleClick("Certificado Digital")}
        >
          Certificado Digital
        </button>
      </div>
      <div ref={indicatorRef} className="indicator" />
    </nav>
  );
};

export default Toolbar;