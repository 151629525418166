import React from 'react';

const IdAcademy = () => (
  <div className="idacademy">
    <div>
      <img
        src="https://www.andrefelizardo.com.br/blog/wp-content/uploads/2015/11/em_construcao1-620x264.jpg"
        alt="Em construção"
        className="construction-image"
      />
    </div>
  </div>
);

export default IdAcademy;