import React from 'react';
import { Outlet } from 'react-router-dom';

// Componente Layout, usado para estruturar o layout da aplicação
const Layout = () => {
  return (
    <div>
      {/* O Outlet renderiza a página específica, dependendo da rota */}
      <Outlet />
    </div>
  );
};

export default Layout;